/* eslint-disable no-prototype-builtins */
/* eslint-disable dot-notation */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { createPopper } from '@popperjs/core'
import { Collapse, ScrollSpy } from 'bootstrap'
import device from 'current-device'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'
import { SplitText } from 'gsap/SplitText.js'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin.js'
import { CSSPlugin } from 'gsap/CSSPlugin.js'
import Splide from '@splidejs/splide'

console.log("It's working!")

const breakpointSM = 576
const breakpointMD = 768
const breakpointLG = 992
const breakpointXL = 1280
const breakpointXXL = 1600
const scrollEase = 'power3.inOut'
const pageTransitionSpeed = 1.25
const langDE = 'de'
const langEN = 'en'
const langFR = 'fr'
let lang
let startAniTrigger = 'top 95%'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(SplitText)
gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(CSSPlugin)

window.addEventListener('pageshow', function (event) {
  const historyTraversal =
    event.persisted ||
    (typeof window.performance !== 'undefined' &&
      window.performance.navigation.type === 2)
  if (historyTraversal) {
    // Handle page restore.
    // window.location.reload()
    pageTransitionIn()
  }
})

window.onload = function () {
  console.log('onload')

  if (device.mobile()) {
    startAniTrigger = 'top 100%'
  }

  showLoader()
  pageTransitionIn()
  ready()
}

function ready() {
  console.log('ready')
  initApp()

  window.addEventListener('resize', onResize)
}

function initApp() {
  console.log('initApp')
  console.log('Collapse = ', Collapse)
  console.log('ScrollSpy = ', ScrollSpy)

  lang = document.documentElement.lang
  console.log('lang = ', lang)
  document.querySelector('body').classList.add('loaded')

  hashHandling()
  initLinksHandling()

  initSectionBrand()
  initSectionWhatWeDo()
  initSectionOurOffer()
  initSectionOurClients()
  initSectionQuotes()
  initSectionWhoWeAre(false)
  initSectionOurNetwork()
  initSectionSocialWall()
  initSectionCareer()
  initSectionContact()

  initHeader()
  initMainNav()
  initInlineLinks()
  initMobileNav()
  initMobileNavTransition()
  initHamburgerTransition()
  initNextPrevNav()
  initLottiBottomButtons()
  initTopButton()
  initAnimations()

  onResize()

  setTimeout(() => {
    onResize()
  }, 100)

  console.log('device.mobile() === %s', device.mobile())
  console.log('device.tablet() === %s', device.tablet())
  console.log('device.desktop() === %s', device.desktop())
}

function initAnimations() {
  console.log('initAnimations')
  if (!device.mobile()) {
    initSectionHeadlines()

    if (isSubpage()) {
      initSubpage()
    }

    setTimeout(() => {
      initSectionFemaleView()
      initSectionCopies()

      if (isSubpage()) {
        initSectionCopiesHighlights()
      }
    }, 100)
  } else {
    if (isSubpage()) {
      initSubpage()
    }

    setTimeout(() => {
      initSectionFemaleView()
      initSectionCopies()

      if (isSubpage()) {
        initSectionCopiesHighlights()
      }
    }, 100)
  }
}

function showLoader() {
  console.log('showLoader')
  gsap.fromTo(
    '#lotti-loader',
    { autoAlpha: 0 },
    {
      autoAlpha: 1,
      duration: 0.5,
      ease: 'power3.inOut',
      delay: 0
    }
  )
}

function pageTransitionIn() {
  console.log('pageTransitionIn')

  const tl = gsap.timeline({})
  tl.fromTo(
    '#loader-bg',
    { xPercent: 0 },
    {
      xPercent: 100,
      duration: pageTransitionSpeed,
      ease: 'power3.inOut'
    },
    '+=1'
  ).fromTo(
    '#lotti-loader',
    { autoAlpha: 1 },
    {
      autoAlpha: 0,
      duration: 0.5,
      ease: 'power3.inOut'
    },
    '-=1'
  )
}

function pageTransitionOut(page) {
  console.log('pageTransitionOut')
  gsap.fromTo(
    '#loader-bg',
    { xPercent: -100 },
    {
      xPercent: 0,
      duration: pageTransitionSpeed,
      ease: 'power3.inOut',
      onComplete: callNextPage,
      onCompleteParams: [page]
    }
  )
}

function callNextPage(page) {
  console.log('callNextPage = ', page)
  window.location = page
}

function hashHandling() {
  console.log('hashHandling')
  const hash = window.location.hash
  console.log('hash = ', hash)
  if (hash !== '') scrollToHash(hash)
}

function initLinksHandling() {
  // Handle Links _self or _blank automatically
  const links = document.getElementsByTagName('a')
  for (let i = 0; i < links.length; i++) {
    if (links[i].host !== window.location.host) {
      links[i].setAttribute('target', '_blank')
    }

    if (
      links[i].hostname !== window.location.hostname ||
      links[i].pathname === window.location.pathname
    ) {
      continue
    }

    links[i].addEventListener('click', function (event) {
      event.preventDefault()
      console.log('click')
      console.log('event.currentTarget = ', event.currentTarget)
      pageTransitionOut(event.currentTarget.href)
    })
  }
}

function initHeader() {
  console.log('initHeader')

  const header = document.getElementById('header')
  const navLinks = document.getElementsByClassName('nav-link-desktop')
  const navLinksLang = document.getElementsByClassName('nav-link-lang-desktop')
  console.log(navLinksLang)

  header.addEventListener(
    'mouseover',
    function (event) {
      document.getElementById('title').classList.remove('show')
      document.getElementById('title').classList.add('hide')
      for (let i = 0; i < navLinks.length; i++) {
        const el = navLinks[i]
        el.classList.remove('hide')
        el.classList.add('show')
      }
      for (let i = 0; i < navLinksLang.length; i++) {
        const el = navLinksLang[i]
        el.classList.remove('hide')
        el.classList.add('show')
      }
      document.getElementById('nav-line').classList.remove('hide')
      document.getElementById('nav-line').classList.add('show')
    },
    false
  )

  header.addEventListener(
    'mouseleave',
    function (event) {
      document.getElementById('title').classList.remove('hide')
      document.getElementById('title').classList.add('show')
      for (let i = 0; i < navLinks.length; i++) {
        const el = navLinks[i]
        el.classList.remove('show')
        el.classList.add('hide')
      }
      for (let i = 0; i < navLinksLang.length; i++) {
        const el = navLinksLang[i]
        el.classList.remove('show')
        el.classList.add('hide')
      }
      document.getElementById('nav-line').classList.remove('show')
      document.getElementById('nav-line').classList.add('hide')
    },
    false
  )
}

function initNextPrevNav() {
  if (document.body.contains(document.getElementById('next'))) {
    console.log('initNextPrevNav')

    const nextBtn = document.getElementById('next')
    nextBtn.addEventListener('click', () => {
      scrollToNextSection('next')
    })

    ScrollTrigger.create({
      trigger: '#section-contact',
      start: 'top 15%',
      end: 'top 15%',
      onEnter: () => {
        gsap.to(nextBtn, { autoAlpha: 0, duration: 0.25 })
      },
      onEnterBack: () => {
        gsap.to(nextBtn, { autoAlpha: 1, duration: 0.25 })
      }
      // markers: true
    })
  }
}

function initTopButton() {
  if (document.body.contains(document.getElementById('top-button'))) {
    console.log('initTopButton')
    const topBtn = document.getElementById('top-button')
    topBtn.onclick = (e) => {
      console.log('desktopNavLink')
      const hash = e.target.parentNode.hash
      console.log('hash = ', hash)

      e.preventDefault()
      scrollToHash(hash)
    }
  }
}

function scrollToNextSection(dir) {
  console.log('scrollToNextSection', dir)
  const sections = [...document.querySelectorAll('.section')]

  const y = sections.filter(function (el, i) {
    console.log(el.getBoundingClientRect().bottom)
    if (dir === 'next') {
      return el.getBoundingClientRect().bottom > 1
    } else {
      return el.getBoundingClientRect().bottom < 0
    }
  })

  console.log(y)

  let nextEl
  let nextID
  let hash
  if (y.length !== 0) {
    if (y.length > 1) {
      nextEl = y[1]
    } else {
      nextEl = y[0]
    }
    nextID = nextEl.id
    hash = '#' + nextID

    console.log('nextID = ', nextID)

    scrollToHash(hash)
  }
}

function initInlineLinks() {
  const inlineLinks = document.getElementsByClassName('inline-link')
  console.log('inlineLinks = ', inlineLinks)

  for (let i = 0; i < inlineLinks.length; i++) {
    const el = inlineLinks[i]

    el.onclick = (e) => {
      console.log('inlineLinks', e)
      const hash = e.target.hash
      console.log('hash = ', hash)
      console.log('e.target = ', e.target)

      e.preventDefault()
      scrollToHash(hash)
    }
  }
}

function initMainNav() {
  const desktopNavLink = document.querySelectorAll('.nav-link-desktop')

  const navLinkHover = document.getElementById('nav-link-1')
  const navUlSub = document.getElementById('nav-item-1')
  gsap.set('#nav-ul-sub', { autoAlpha: 0 })

  navLinkHover.addEventListener('mouseover', () => {
    gsap.to('#nav-ul-sub', { autoAlpha: 1, duration: 0.35 })
  })

  navUlSub.addEventListener('mouseleave', () => {
    gsap.to('#nav-ul-sub', { autoAlpha: 0, duration: 0.35 })
  })

  for (let i = 0; i < desktopNavLink.length; i++) {
    const el = desktopNavLink[i]

    el.onclick = (e) => {
      console.log('desktopNavLink')
      const hash = e.target.hash
      console.log('hash = ', hash)
      console.log('e.target = ', e.target)

      if (hash === '') return

      if (
        isSubpage() ||
        isJobpage() ||
        isMetapage() ||
        isNewsletterpage() ||
        isErrorpage()
      ) {
        pageTransitionOut('/' + lang + hash)
      } else {
        e.preventDefault()
        scrollToHash(hash)
      }
    }
  }
}

function initMobileNav() {
  console.log('initMobileNav')
  const hamburger = document.getElementById('hamburger')
  const mobileNav = document.getElementById('mobile-nav')
  const mobileNavLink = document.querySelectorAll('.nav-link-mobile')

  gsap.set('#mobile-nav', {
    left: 0,
    xPercent: 100
  })

  hamburger.onclick = () => {
    console.log('hamburger click')
    mobileNav.classList.toggle('show')

    if (mobileNav.classList.contains('show')) {
      mobileTimeline.play()
      console.log('mobileTimeline.duration = ', mobileTimeline.duration())
      hamburgerTimeline.play()
      document.querySelector('body').classList.add('no-scroll')
    } else {
      mobileTimeline.reverse()
      console.log('mobileTimeline.duration = ', mobileTimeline.duration())
      hamburgerTimeline.reverse()
      document.querySelector('body').classList.remove('no-scroll')
    }
  }

  for (let i = 0; i < mobileNavLink.length; i++) {
    const el = mobileNavLink[i]

    el.onclick = (e) => {
      e.preventDefault()

      mobileNav.classList.toggle('show')
      mobileTimeline.reverse()
      console.log('mobileTimeline.duration = ', mobileTimeline.duration())
      hamburgerTimeline.reverse()
      document.querySelector('body').classList.remove('no-scroll')

      const hash = e.target.hash
      console.log('hash = ', hash)
      console.log('e.target = ', e.target)

      if (
        isSubpage() ||
        isJobpage() ||
        isMetapage() ||
        isNewsletterpage() ||
        isErrorpage()
      ) {
        pageTransitionOut('/' + lang + hash)
      } else {
        e.preventDefault()
        setTimeout(() => {
          scrollToHash(hash)
        }, 1000)
      }
    }
  }
}

const hamburgerTimeline = gsap.timeline({ paused: true })
function initHamburgerTransition() {
  hamburgerTimeline
    .addLabel('start', '+=0')
    .to('#top', { y: 4, duration: 0.5, ease: 'power3.inOut' }, 'start')
    .to(
      '#bottom',
      { y: -4, rotation: -45, duration: 0.5, ease: 'power3.inOut' },
      'start'
    )
    .to(
      ['#top', '#middle'],
      { rotation: 45, duration: 0.5, ease: 'power3.inOut' },
      'start'
    )
    .to(
      '#hamburger-inner',
      {
        rotation: 180,
        duration: 0.5,
        ease: 'power3.inOut',
        transformOrigin: 'center center'
      },
      'start+=0.25'
    )
}

const mobileTimeline = gsap.timeline({ paused: true })
function initMobileNavTransition() {
  console.log('initMobileNavTransition')

  const childSplit = getChildSplit('.mba')
  const parentSplit = getParentSplit('.mba')
  mobileTimeline
    .fromTo(
      '#mobile-nav',
      1.5,
      {
        xPercent: 100,
        transformOrigin: 'left top'
      },
      {
        xPercent: 0,
        ease: 'power4.inOut'
      }
    )
    .fromTo(
      childSplit.lines,
      1,
      { yPercent: 100 },
      { yPercent: 0, ease: 'power2.inOut', stagger: 0.1 },

      '-=0.85'
    )
    .fromTo(
      '#mb-nav-line',
      { autoAlpha: 0, scale: 0 },
      { autoAlpha: 0.5, scale: 1, duration: 1, ease: 'power2.inOut' },

      '-=1.25'
    )
}

function scrollToHash(hash, force = false) {
  console.log('scrollToHash = ', hash)
  console.log('force = ', force)

  // const scrollTop = document.documentElement.scrollTop
  let scrollDur = getSectionAniDuration(hash)

  // window.scrollTo(0, scrollTop)
  // setTimeout(function () {
  //   window.scrollTo(0, scrollTop)
  // }, 1)

  const scaleVal = 1 - gsap.getProperty('#video-wrapper', 'scale')

  if (hash === '#section-female-view') {
    scrollDur += scaleVal
  }

  gsap.to(window, {
    duration: scrollDur,
    scrollTo: { y: hash },
    ease: scrollEase,
    delay: 0
  })

  if (!device.mobile()) {
    if (force) {
      gsap.delayedCall(scrollDur, forceShowReel)
    }
  }
}

function getSectionAniDuration(hash) {
  const browserHeight = window.innerHeight
  const scrollTop = document.documentElement.scrollTop
  console.log('test ', document.getElementById(hash.substring(1)))
  let sectionTop = 0
  if (!isSubpage()) {
    sectionTop = document.getElementById(hash.substring(1)).offsetTop
  }

  if (hash === '#section-what-we-do' && !isSubpage()) {
    console.log('yes')
    sectionTop = document.getElementById('section-our-brand').offsetTop * 2
  }

  console.log('browserHeight = ', browserHeight)
  console.log('scrollTop = ', scrollTop)
  console.log('sectionTop = ', sectionTop)

  let duration = Math.abs((sectionTop - scrollTop) / 2000)
  console.log('duration = ', duration)

  if (duration < 1.5) duration = 1.5
  if (duration > 4.5) duration = 4.5

  console.log('duration = ', duration)
  return duration
}

function getChildSplit(el) {
  const childSplit = new SplitText(el, {
    type: 'lines',
    linesClass: 'split-child'
  })
  return childSplit
}

function getParentSplit(el) {
  const parentSplit = new SplitText(el, {
    linesClass: 'split-parent'
  })
  return parentSplit
}

function initSectionHeadlines() {
  const lines = document.querySelectorAll('.lines-ani')
  console.log('initSectionHeadlines = ', lines)

  for (let i = 0; i < lines.length; i++) {
    const el = lines[i]
    const childSplit = getChildSplit(el)
    const parentSplit = getParentSplit(el)
    gsap.from(childSplit.lines, {
      duration: 1,
      yPercent: 100,
      ease: 'power2.inOut',
      stagger: 0.1,
      scrollTrigger: {
        trigger: el,
        start: startAniTrigger,
        end: 'bottom top'
        // markers: true
      },
      onComplete: () => {
        // parentSplit.revert()
        // childSplit.revert()
      }
    })
  }
}

function initSectionCopies() {
  const copy = document.querySelectorAll('.lines-ani-copy p')
  for (let i = 0; i < copy.length; i++) {
    const el = copy[i]
    gsap.from(el, {
      duration: 1,
      yPercent: 25,
      autoAlpha: 0,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: el,
        start: startAniTrigger,
        end: 'bottom top'
        // markers: true
      }
    })
  }
}

function initSectionCopiesHighlights() {
  console.log('initSectionCopiesHighlights')
  const bgHighlight = document.getElementsByClassName('bg-highlight')
  for (let i = 0; i < bgHighlight.length; i++) {
    const element = bgHighlight[i]
    console.log(element)
    const dur = Math.ceil(element.innerHTML.length / 48)
    console.log('element.innerHTML.length = ', element.innerHTML.length)
    console.log('dur = ', dur)

    const isAnswers =
      document.URL.includes('unser-ansatz') ||
      document.URL.includes('our-approach')

    if (isAnswers) {
      gsap.fromTo(
        element,
        { color: '#f5f2f0', backgroundSize: '0 100%' },
        {
          duration: dur,
          color: '#262729',
          backgroundSize: '100% 100%',
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: element,
            start: startAniTrigger,
            end: 'bottom top'
            // markers: true
          }
        }
      )
    } else {
      gsap.to(element, {
        duration: dur,
        xPercent: 0,
        backgroundSize: '100% 100%',
        ease: 'power3.inOut',
        stagger: 0.1,
        scrollTrigger: {
          trigger: element,
          start: startAniTrigger,
          end: 'bottom top'
          // markers: true
        }
      })
    }
  }
}

function getSingleLineAni(id, revert = false) {
  const el = document.querySelectorAll(id)
  const childSplit = getChildSplit(el)
  const parentSplit = getParentSplit(el)

  const tl = gsap.timeline({ paused: true })
  tl.from(childSplit.lines, {
    duration: 0.75,
    yPercent: 100,
    stagger: 0.1,
    ease: 'power2.out',
    onComplete: () => {
      if (revert) {
        parentSplit.revert()
        childSplit.revert()
        initLinksHandling()
      }
    }
  })

  return tl
}

function initCopyColorsAni(parentID) {
  console.log('initCopyColorsAni = ', parentID)

  const tl = gsap.timeline({ paused: true })

  const mySplitText = new SplitText(parentID + ' p', {
    type: 'chars,words,chars'
  })
  const words = mySplitText.words // an array of all the divs that wrap each character

  tl.addLabel('start', '+=0')
    .from(
      words,
      {
        duration: 0.5,
        opacity: 0,
        stagger: 0.05
      },
      'start'
    )
    .to(
      parentID,
      {
        duration: 0.5,
        opacity: 1
      },
      'start+=0.1'
    )
    .call(addInlineLinkClass, [parentID])

  return tl
}

function addInlineLinkClass(parentID) {
  console.log(
    'addInlineLinkClass ',
    parentID,
    document.getElementById(parentID.substring(1))
  )
  const searchEles = document.getElementById(parentID.substring(1)).children
  for (let i = 0; i < searchEles.length; i++) {
    console.log(searchEles[i].tagName)
    if (searchEles[i].tagName === 'P') {
      const searchElChildren = searchEles[i].children
      for (let j = 0; j < searchElChildren.length; j++) {
        if (searchElChildren[j].tagName === 'A') {
          searchElChildren[j].classList.add('activate')
        }
      }
    }
  }
}

function forceShowReel() {
  console.log('forceShowReel')
  const browserHeight = window.innerHeight
  const scrollTop = document.documentElement.scrollTop
  console.log(scrollTop)

  gsap.to(window, {
    scrollTo: { y: scrollTop + browserHeight },
    duration: 1
  })
}

function initSectionFemaleView() {
  if (document.body.contains(document.getElementById('section-female-view'))) {
    console.log('initSectionFemaleView')

    // const playShowreel = document.getElementById('play-showreeel')
    // playShowreel.onclick = (e) => {
    //   e.preventDefault()
    //   console.log(e.target.parentNode)
    //   const hash = e.target.parentNode.hash
    //   scrollToHash(hash, true)
    // }

    // Headline and SVGs
    const headlineTL = getSingleLineAni('#female-view-headline', true)
    const pathFemaleTL = getPathAni('#path-female')
    const pathCommunicationTL = getPathAni('#path-communication')
    const pathOrganizationTL = getPathAni('#path-organization')

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#female-view-headline',
        start: startAniTrigger,
        end: 'bottom top'
        // toggleActions: 'restart none none reverse'
        // markers: true
      }
    })

    tl.addLabel('start', '+=1.5')
      .add(headlineTL.play(), 'start')
      .call(resizeCircles)
      .add(pathFemaleTL.play())
      .add(pathCommunicationTL.play())
      .add(pathOrganizationTL.play())
      .fromTo(
        '.social-nav-item-female-view',
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 1, stagger: 0.2 },
        'start+=1'
      )
  }
}

function initSectionBrand() {
  if (document.body.contains(document.getElementById('section-our-brand'))) {
    let newStrings = [
      ['Agentur', 'und Kunde'],
      ['Kampagne', 'und Content'],
      ['Bauch', 'und Kopf'],
      ['Menschen', 'und Marken']
    ]

    if (lang === langEN) {
      newStrings = [
        ['agency', 'and client'],
        ['campaign', 'and content'],
        ['mind', 'and instinct'],
        ['brands', 'and people']
      ]
    }

    if (lang === langFR) {
      newStrings = [
        ['agence', 'et client'],
        ['campagne', 'et contenu'],
        ['intellect', 'et instinct'],
        ['les marques', 'et les individus']
      ]
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#brand-headline',
        start: startAniTrigger,
        end: 'bottom top'
        // toggleActions: 'restart none none reverse'
        // markers: true
      }
    })

    if (!device.mobile()) {
      const headlineTL = getSingleLineAni('#brand-headline', true)
      tl.addLabel('start', '+=0')
        .add(headlineTL.play(), 'start')
        .add(gsap.delayedCall(2, fadeOutWords))
    } else {
      gsap.delayedCall(2, fadeOutWords)
    }

    let id = 0
    function fadeOutWords() {
      gsap.to(['#change-0', '#change-1'], {
        autoAlpha: 0,
        duration: 0.5,
        onComplete: fadeInWords
      })
    }

    function fadeInWords() {
      const change0 = document.getElementById('change-0')
      const change1 = document.getElementById('change-1')

      change0.innerHTML = ''
      change1.innerHTML = ''

      change0.insertAdjacentHTML('beforeend', newStrings[id][0] + ' ')
      change1.insertAdjacentHTML('beforeend', newStrings[id][1] + '.')

      const lineHeight = change0.offsetHeight

      gsap.set(['#change-0', '#change-1'], {
        top: lineHeight,
        autoAlpha: 1
      })

      if (device.mobile()) {
        gsap.to(['#change-0', '#change-1'], {
          top: 0,
          duration: 0.75,
          stagger: 0.1,
          ease: 'power2.out'
        })
      } else {
        gsap.to(['#change-0', '#change-1'], {
          top: 0,
          duration: 0.75,
          ease: 'power2.out'
        })
      }

      id++
      if (id === newStrings.length) {
        id = 0
      }

      gsap.delayedCall(2, fadeOutWords)
    }

    gsap.to('#circle-left', {
      xPercent: -150,
      ease: 'linear',
      scrollTrigger: {
        trigger: '#circle-left',
        start: 'top bottom',
        end: 'bottom top',
        scrub: true
      }
    })

    gsap.to('#circle-right', {
      xPercent: 150,
      ease: 'linear',
      scrollTrigger: {
        trigger: '#circle-right',
        start: 'top bottom',
        end: 'bottom top',
        scrub: true
      }
    })
  }
}

let ourWorkSectionTL
function initSectionWhatWeDo() {
  if (document.body.contains(document.getElementById('section-what-we-do'))) {
    console.log('initSectionWhatWeDo')
    const videoEl = document.getElementById('video')
    const videoWrapperEl = document.getElementById('video-wrapper')
    const soundOnIcon = document.getElementById('sound-on-icon')
    const soundOffIcon = document.getElementById('sound-off-icon')
    const toggleSound = document.getElementById('toggle-sound')

    videoWrapperEl.onclick = (e) => {
      if (videoEl.muted === true) {
        videoEl.muted = false
        gsap.set(soundOnIcon, { autoAlpha: 0 })
        gsap.set(soundOffIcon, { autoAlpha: 1 })
      } else {
        videoEl.muted = true
        gsap.set(soundOnIcon, { autoAlpha: 1 })
        gsap.set(soundOffIcon, { autoAlpha: 0 })
      }
    }

    if (device.desktop()) {
      gsap.set(toggleSound, { autoAlpha: 0 })
      gsap.set(soundOffIcon, { autoAlpha: 0 })

      videoWrapperEl.onmouseenter = (e) => {
        gsap.to(toggleSound, {
          duration: 0.2,
          autoAlpha: 1,
          ease: 'power1'
        })
      }

      videoWrapperEl.onmouseleave = (e) => {
        gsap.to(toggleSound, {
          duration: 0.2,
          autoAlpha: 0,
          ease: 'power1'
        })
      }

      document.onmousemove = (e) => {
        gsap.to(toggleSound, {
          duration: 0.2,
          css: { left: e.clientX, top: e.clientY },
          ease: 'power1'
        })
      }
    }

    if (!device.mobile()) {
      ourWorkSectionTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#section-what-we-do',
          pin: true,
          start: 'top top',
          end: 'bottom top',
          scrub: true
        }
      })

      ourWorkSectionTL
        .addLabel('start', '+=0')
        .fromTo(
          '#what-we-do-headline',
          {
            scale: 1,
            autoAlpha: 1
          },
          {
            duration: 5,
            scale: 0.75,
            autoAlpha: 0,
            ease: 'linear'
          },
          'start'
        )
        .fromTo(
          '#video-wrapper',
          {
            scale: 0.25,
            autoAlpha: 0.5
          },
          {
            duration: 5,
            scale: 1,
            autoAlpha: 1,
            ease: 'linear'
          },
          'start'
        )
        .fromTo(
          '#scroll-sign',
          {
            autoAlpha: 1
          },
          {
            duration: 5,
            autoAlpha: 0,
            ease: 'linear'
          },
          'start'
        )
    }

    ScrollTrigger.create({
      trigger: '#video-wrapper',
      start: 'top bottom',
      end: 'top bottom',

      onEnter: () => {
        videoEl.play()
      },
      onLeaveBack: () => {
        const playPromise = videoEl.pause()

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              videoEl.pause()
            })
            .catch((_error) => {
              videoEl.pause()
            })
        }
      }
    })

    ScrollTrigger.create({
      trigger: '#section-our-offer',
      start: 'top top',
      end: 'top top',

      onEnter: () => {
        const playPromise = videoEl.play()

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              videoEl.pause()
            })
            .catch((_error) => {
              videoEl.pause()
            })
        }
      },
      onLeaveBack: () => {
        const playPromise = videoEl.play()

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              videoEl.play()
            })
            .catch((_error) => {
              videoEl.pause()
            })
        }
      }
    })
  }
}

function getPathAni(id, count = -1) {
  console.log('getSVGAni')
  console.log('id= ', id)
  console.log('count= ', count)

  let dur = 1
  if (count === 0 || count === 3) {
    dur = 1.75
  }

  const tl = gsap.timeline({ paused: true })
  tl.fromTo(
    id,
    {
      drawSVG: '0'
    },
    {
      drawSVG: '100%',
      duration: dur,
      ease: 'power3.inOut'
    }
  )

  return tl
}

function initSectionOurOffer() {
  if (document.body.contains(document.getElementById('section-our-offer'))) {
    console.log('initSectionOurOffer')
    if (!device.mobile()) {
      const tlAni1 = getSingleLineAni('#single-line-ani-0')
      const tlAni2 = getSingleLineAni('#col-1-single-line-ani-0 p')
      const tlAni3 = getSingleLineAni('#col-2-single-line-ani-0 p')

      const tlAni4 = getSingleLineAni('#single-line-ani-1')
      const tlAni5 = getSingleLineAni('#col-1-single-line-ani-1 p')
      const tlAni6 = getSingleLineAni('#col-2-single-line-ani-1 p')

      const tlAni7 = getSingleLineAni('#single-line-ani-2')
      const tlAni8 = getSingleLineAni('#col-1-single-line-ani-2 p')
      const tlAni9 = getSingleLineAni('#col-2-single-line-ani-2 p')

      const line1 = document.getElementById('line-ani-0')
      const line2 = document.getElementById('line-ani-1')

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#ccc-wrapper',
          start: startAniTrigger,
          end: 'bottom top'
          // toggleActions: 'restart none none reverse'
          // markers: true
        }
      })

      tl.addLabel('start', '+=0')
        .add(tlAni1.play(), 'start+=.6')
        .add(tlAni2.play(), 'start+=.7')
        .add(tlAni3.play(), 'start+=.8')

        .add(tlAni4.play(), 'start+=1.6')
        .add(tlAni5.play(), 'start+=1.7')
        .add(tlAni6.play(), 'start+=1.8')

        .add(tlAni7.play(), 'start+=2.3')
        .add(tlAni8.play(), 'start+=2.4')
        .add(tlAni9.play(), 'start+=2.5')

        .from(
          line1,
          { scaleX: 0, duration: 1, ease: 'power3.inOut' },
          'start+=0'
        )
        .from(
          line2,
          { scaleX: 0, duration: 1, ease: 'power3.inOut' },
          'start+=0.9'
        )
    }
  }
}

function initSectionOurClients() {
  if (document.body.contains(document.getElementById('section-our-clients'))) {
    console.log('initSectionOurClients')
    if (!device.mobile()) {
      const tlAni1 = getSingleLineAni('#our-clients-copy-left p')
      const tlAni2 = getSingleLineAni('#our-clients-copy-right p')

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#our-clients-headline',
          start: startAniTrigger,
          end: 'bottom top'
          // toggleActions: 'restart none none reverse'
          // markers: true
        }
      })

      tl.addLabel('start', '+=0').add(tlAni1.play(), 'start+=.6')
      if (device.mobile()) {
        tl.add(tlAni2.play(), 'start+=1.4')
      } else {
        tl.add(tlAni2.play(), 'start+=.6')
      }
    }
  }
}

function initSectionQuotes() {
  if (document.body.contains(document.getElementById('section-quotes'))) {
    console.log('initSectionQuotes')

    const pathQuote0 = document.getElementById('path-quote-0')
    console.log('pathQuote0', pathQuote0)

    gsap.fromTo(
      pathQuote0,
      {
        drawSVG: '0'
      },
      {
        drawSVG: '100%',
        duration: 1,
        ease: 'power3.inOut',
        scrollTrigger: {
          trigger: pathQuote0,
          start: startAniTrigger,
          end: 'bottom top'
          // markers: true
        }
      }
    )

    const pathQuotes = gsap.utils.toArray('.path-quotes')

    const splide = new Splide('#splide-quotes', {
      type: 'loop',
      arrows: false,
      autoHeight: true,
      // autoplay: 'play',
      speed: 600
    })
    splide.mount()

    let slides = document.getElementsByClassName('block-type-quote')
    let splide01Track = document.getElementById('splide-quotes-track')
    let slidesHeight = []
    let gap = 80
    let currentIdex = 0
    console.log('slides = ', slides)
    for (let i = 0; i < slides.length; i++) {
      slidesHeight.push(slides[i].children[0].offsetHeight)
    }
    console.log('slidesHeight = ', slidesHeight)
    if (device.mobile()) {
      splide01Track.style.height = Math.ceil(slidesHeight[0]) + gap + 'px'
    }
    splide.on('move', function (newIndex, oldIndex, destIndex) {
      const cloneArray = pathQuotes.slice()
      cloneArray.splice(oldIndex, 1)
      for (let i = 0; i < cloneArray.length; i++) {
        const element = cloneArray[i]
        const tl = gsap.timeline({})
        tl.set(element, {
          drawSVG: '0'
        })
      }
      if (device.mobile()) {
        splide01Track.style.height =
          Math.ceil(slidesHeight[newIndex]) + gap + 'px'
      }
    })
    splide.on('moved', function (newIndex, oldIndex, destIndex) {
      const tl = gsap.timeline({})
      tl.fromTo(
        pathQuotes[newIndex],
        {
          drawSVG: '0'
        },
        {
          drawSVG: '100%',
          duration: 1,
          ease: 'power3.inOut'
        }
      )
      currentIdex = newIndex
    })
    splide.on('resize', function () {
      slides = document.getElementsByClassName('block-type-quote')
      splide01Track = document.getElementById('splide-quotes-track')
      slidesHeight = []
      gap = 80
      for (let i = 0; i < slides.length; i++) {
        slidesHeight.push(slides[i].children[0].offsetHeight)
      }
      if (device.mobile()) {
        splide01Track.style.height =
          Math.ceil(slidesHeight[currentIdex]) + gap + 'px'
      }
    })
  }
}

function initSectionWhoWeAre(fromResize) {
  if (document.body.contains(document.getElementById('section-who-we-are'))) {
    console.log('initSectionWhoWeAre = ', fromResize)

    const gap = 30
    let splideTeamTrack

    if (!fromResize) {
      if (!device.mobile()) {
        // Copy Animation
        const aniTL = initCopyColorsAni('#who-we-are-copy')

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '#who-we-are-copy',
            start: startAniTrigger,
            end: 'bottom top'
            // toggleActions: 'restart none none reverse'
            // markers: true
          }
        })

        tl.add(aniTL.play())
      }

      const splideTeam = new Splide('#splide-team', {
        type: 'loop',
        arrows: false,
        autoHeight: true,
        // autoplay: true,
        speed: 600
      })
      splideTeam.mount()

      if (device.mobile()) {
        splideTeam.on('move', function (newIndex, oldIndex, destIndex) {
          const slides = document.getElementsByClassName('team-slide')
          splideTeamTrack = document.getElementById('splide-team-track')
          const slidesHeight = []
          const circlePlus = document.querySelectorAll('.circle-plus-icon')
          const bioText = document.querySelectorAll('.bio-text')
          const bioQuote = document.querySelectorAll('.bio-quote')

          for (let i = 0; i < slides.length; i++) {
            slidesHeight.push(slides[i].children[0].offsetHeight)
            circlePlus[i].classList.remove('active')
            bioText[i].classList.remove('show')
            bioText[i].classList.add('hide')
            bioQuote[i].classList.remove('hide')
            bioQuote[i].classList.add('show')
          }

          splideTeamTrack.style.height =
            Math.ceil(slidesHeight[newIndex]) + gap + 'px'
        })
      }
    }

    if (fromResize) {
      // Circle Plus Interaction
      const teamCardWrapper = document.querySelectorAll('.team-card-wrapper')
      const bioWrapper = document.querySelectorAll('.bio-wrapper')
      const circlePlus = document.querySelectorAll('.circle-plus-icon')
      const bioInfoWrapper = document.querySelectorAll('.bio-info-wrapper')
      const bioText = document.querySelectorAll('.bio-text')
      const bioQuote = document.querySelectorAll('.bio-quote')

      splideTeamTrack = document.getElementById('splide-team-track')

      for (let i = 0; i < circlePlus.length; i++) {
        const el = circlePlus[i]
        const teamCardWrapperEl = teamCardWrapper[i]
        const bioWrapperEl = bioWrapper[i]
        const bioInfoWrapperEl = bioInfoWrapper[i]
        const bioTextEl = bioText[i]
        const bioTextElHeight = bioText[i].offsetHeight
        const bioQuoteEl = bioQuote[i]
        const bioQuoteElHeight = bioQuote[i].offsetHeight

        if (el.classList.contains('active')) {
          bioInfoWrapperEl.style.height = bioTextElHeight + gap + 'px'
        } else {
          bioInfoWrapperEl.style.height = bioQuoteElHeight + gap + 'px'
        }

        el.onclick = (e) => {
          e.preventDefault()
          console.log(e.target)
          el.classList.toggle('active')
          bioTextEl.classList.toggle('show')
          bioQuoteEl.classList.toggle('hide')

          if (el.classList.contains('active')) {
            bioInfoWrapperEl.style.height = bioTextElHeight + gap + 'px'
          } else {
            bioInfoWrapperEl.style.height = bioQuoteElHeight + gap + 'px'
          }

          if (device.mobile()) {
            splideTeamTrack.style.height =
              bioWrapperEl.offsetHeight +
              teamCardWrapperEl.offsetHeight +
              gap +
              'px'
          }

          console.log('teamCardWrapperEl ', teamCardWrapperEl.offsetHeight)

          console.log('bioTextElHeight = ', bioTextElHeight)
          console.log('bioQuoteElHeight = ', bioQuoteElHeight)
        }
      }
    }
  }
}

function initSectionOurNetwork() {
  if (document.body.contains(document.getElementById('section-our-network'))) {
    console.log('initSectionOurNetwork')
    if (!device.mobile()) {
      // Get Copy Timline
      const aniTL = initCopyColorsAni('#our-network-copy')

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#our-network-copy',
          start: startAniTrigger,
          end: 'bottom top'
          // toggleActions: 'restart none none reverse'
          // markers: true
        }
      })

      tl.add(aniTL.play())
    }
  }
}

function initSectionCareer() {
  if (document.body.contains(document.getElementById('section-career'))) {
    console.log('initSectionCareer')

    if (!device.mobile()) {
      const aniTL = gsap.timeline({ paused: true })

      const mySplitText = new SplitText('#career-copy' + ' p', {
        type: 'words,chars'
      })
      const words = mySplitText.words // an array of all the divs that wrap each character

      aniTL
        .addLabel('start', '+=0')
        .from(
          words,
          {
            duration: 0.5,
            opacity: 0,
            stagger: 0.05,
            onComplete: () => {
              mySplitText.revert()
            }
          },
          'start'
        )
        .to(
          '#career-copy',
          {
            duration: 0.5,
            opacity: 1
          },
          'start+=0.1'
        )
        .from(
          '#career-emailto',
          {
            autoAlpha: 0,
            duration: 0.5
          },
          '-=0.25'
        )
        .from(
          '.career-jobs',
          {
            autoAlpha: 0,
            duration: 0.5,
            stagger: 0.5
          },
          '-=0.125'
        )

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#career-copy',
          start: startAniTrigger,
          end: 'bottom top'
          // toggleActions: 'restart none none reverse'
          // markers: true
        }
      })

      tl.add(aniTL.play())
    }
  }
}

function initSectionSocialWall() {
  if (document.body.contains(document.getElementById('section-social-wall'))) {
    console.log('initSectionSocialWall')
    setTimeout(() => {
      onResize()
    }, 100)
  }
}

function initSectionContact() {
  if (document.body.contains(document.getElementById('section-contact'))) {
    console.log('initSectionContact')

    if (!device.mobile()) {
      const input = document.getElementById('text7231197')
      input.onclick = (e) => {
        input.removeAttribute('data-bs-toggle')
      }

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#contact-wrapper',
          start: startAniTrigger,
          end: 'bottom top'
          // toggleActions: 'restart none none reverse'
          // markers: true
        }
      })

      tl.addLabel('start', '+=0')
        .from(['#text7231197', '#submit-btn'], { autoAlpha: 0 }, 'start+=1')
        // .add(tlHeadlineNL.play(), 'start+=1.1')
        .from('#headline-nl', { autoAlpha: 0 }, 'start+=1.1')
        .to(
          '#headline-nl span',
          {
            duration: 1,
            xPercent: 0,
            backgroundSize: '100% 100%',
            ease: 'power3.inOut'
          },
          'start+=1.1'
        )
        .from(
          '.contact-details-wrapper',
          {
            autoAlpha: 0,
            duration: 1,
            stagger: 0.2
          },
          'start+=1.1'
        )
        .fromTo(
          '.social-nav-item-contact',
          { autoAlpha: 0 },
          { autoAlpha: 1, duration: 1, stagger: 0.2 },
          'start+=1.4'
        )

      // Newsletter
    }
  }
}

function initSubpage() {
  if (isSubpage()) {
    console.log('initSubpage')

    window.scrollTo(0, 0)

    const isAnswers =
      document.URL.includes('unser-ansatz') ||
      document.URL.includes('our-approach')
    console.log('isAnswers = ', isAnswers)

    let charact
    let yCharactGap
    let tlWords
    let wordsScale = 2
    if (isAnswers) {
      if (device.mobile()) {
        wordsScale = 1
      }
      // Words
      charact = [...document.querySelectorAll('.characts')][0]
      console.log('charact offsetHeight = ', charact.getBoundingClientRect())

      yCharactGap = window.innerHeight / 2 - charact.getBoundingClientRect().top

      tlWords = gsap.timeline({ paused: true })
      tlWords
        .set('.characts', {
          y: yCharactGap,
          scale: wordsScale
        })
        .from('.characts', {
          autoAlpha: 0,
          duration: 0.5,
          stagger: 0.5
        })
        .to('.characts', {
          y: 0,
          scale: 1,
          duration: 1,
          stagger: 0.1,
          ease: 'power4.inOut'
        })
    }

    // Intro
    const headlineIntroTL = getSingleLineAni('#intro-headline')
    const pathCircleIntroTL = getPathAni('#path-circle-intro')
    const pathLineIntroTL = getPathAni('#path-line-intro')
    const scrollSign = getPathAni('#scroll-sign')

    const tlScrollSign = gsap.timeline({ paused: true })
    tlScrollSign.fromTo(
      '#scroll-sign',
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 1,
        ease: 'power3.inOut',
        repeat: -1,
        yoyo: true
      }
    )

    let userHasScrolled = false
    window.onscroll = (e) => {
      if (!userHasScrolled) {
        tlScrollSign.pause()
        gsap.to('#scroll-sign', { autoAlpha: 0 })
      }
      userHasScrolled = true
    }

    const tlIntro = gsap.timeline({
      scrollTrigger: {
        trigger: '#intro-headline',
        start: startAniTrigger,
        end: 'bottom top'
      }
    })

    tlIntro.addLabel('start', '+=1.5')
    if (isAnswers) {
      tlIntro.add(tlWords.play(), 'start')
      tlIntro.add(headlineIntroTL.play(), '-=0.5')
    } else {
      tlIntro.add(headlineIntroTL.play(), 'start')
    }
    tlIntro
      .call(resizeCircles)
      .add(pathCircleIntroTL.play())
      .add(pathLineIntroTL.play())
      .add(tlScrollSign.play())

    // Arrows
    const svgArrow = [...document.querySelectorAll('.svg-arrow')]
    const pathArrow1 = [...document.querySelectorAll('.path-arrow-1')]
    const pathArrow2 = [...document.querySelectorAll('.path-arrow-2')]

    for (let i = 0; i < svgArrow.length; i++) {
      const el = svgArrow[i]
      const path1 = pathArrow1[i]
      const path2 = pathArrow2[i]
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: startAniTrigger,
          end: 'bottom top'
        }
      })
      tl.addLabel('start', '+=0')
        .add(getPathAni(path1, i).play(), 'start')
        .add(getPathAni(path2, i).play(), 'start+=0.3')
    }

    // Outro
    const headlineOutroTL = getSingleLineAni('#outro-headline')
    const pathCircleOutroTL = getPathAni('#path-circle-outro')
    const pathLineOutroTL = getPathAni('#path-line-outro')

    const tlOutro = gsap.timeline({
      scrollTrigger: {
        trigger: '#outro-headline',
        start: startAniTrigger,
        end: 'bottom top'
      }
    })

    tlOutro
      .addLabel('start', '+=0')
      .add(headlineOutroTL.play(), 'start')
      .call(resizeCircles)
      .add(pathCircleOutroTL.play())
      .add(pathLineOutroTL.play())
  }
}

function isJobpage() {
  if (document.URL.includes('jobpages')) {
    return true
  }
}

function isSubpage() {
  if (document.URL.includes('subpages')) {
    return true
  }
}

function isMetapage() {
  if (document.URL.includes('metapages')) {
    return true
  }
}

function isNewsletterpage() {
  if (document.URL.includes('newsletterpages')) {
    return true
  }
}

function isErrorpage() {
  if (document.URL.includes('error')) {
    return true
  }
}

function initLottiBottomButtons() {
  const playerContainers = document.querySelectorAll('.lotti-bottom-button')

  playerContainers.forEach((container) => {
    container.addEventListener('mouseover', () => {
      const player = container.querySelector('lottie-player')
      player.setDirection(1)
      player.play()
    })

    container.addEventListener('mouseleave', () => {
      const player = container.querySelector('lottie-player')
      player.setDirection(-1)
      player.play()
    })
  })
}

let resizeTimer
function onResize() {
  console.log('onResize')

  if (window.innerWidth > breakpointMD) {
    const mobileNav = document.getElementById('mobile-nav')
    if (mobileNav.classList.contains('show')) {
      mobileNav.classList.remove('show')
      mobileTimeline.reverse()
      hamburgerTimeline.reverse()
    }
  }

  // Footer
  const footer = document.getElementById('footer')
  const footerHeight = footer.offsetHeight
  console.log('footerHeight = ', footerHeight)
  footer.style.marginTop = -footerHeight + 'px'

  initSectionWhoWeAre(true)

  resizeCircles()

  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function () {
    resizeCircles()
  }, 1000)
}

function resizeCircles() {
  if (
    document.body.contains(document.getElementById('section-female-view-svgs'))
  ) {
    const svgFemale = document.getElementById('svg-female')
    const wordFemale = document.getElementById('word-female')

    const rectFemale = wordFemale.getBoundingClientRect()

    const gap = 40
    svgFemale.style.width = rectFemale.width + gap + 'px'
    svgFemale.style.top =
      rectFemale.top +
      rectFemale.height / 2 -
      svgFemale.offsetHeight / 2 +
      window.scrollY +
      'px'
    svgFemale.style.left = rectFemale.left + -gap / 2 + 'px'

    const svgCommunication = document.getElementById('svg-communication')
    const wordCommunication = document.getElementById('word-communication')

    const rectCommunication = wordCommunication.getBoundingClientRect()

    svgCommunication.style.top =
      rectCommunication.bottom - 10 + window.scrollY + 'px'
    svgCommunication.style.left = rectCommunication.left + 'px'
    svgCommunication.style.width = rectCommunication.width + 'px'

    const svgOrganization = document.getElementById('svg-organization')
    const wordOrganization = document.getElementById('word-organization')

    const rectOrganization = wordOrganization.getBoundingClientRect()

    svgOrganization.style.top = rectOrganization.bottom + window.scrollY + 'px'
    svgOrganization.style.left = rectOrganization.left + 'px'
    svgOrganization.style.width = rectOrganization.width + 'px'
  }

  if (document.body.contains(document.getElementById('section-subpage-svgs'))) {
    const svgCircleIntro = document.getElementById('svg-circle-intro')
    const wordCircleIntro = document.getElementById('word-circle-intro')

    const rectCircleIntro = wordCircleIntro.getBoundingClientRect()

    svgCircleIntro.style.width = rectCircleIntro.width + 'px'
    svgCircleIntro.style.top =
      rectCircleIntro.top +
      rectCircleIntro.height / 2 -
      svgCircleIntro.offsetHeight / 2 +
      window.scrollY +
      'px'
    svgCircleIntro.style.left = rectCircleIntro.left + 'px'

    if (document.body.contains(document.getElementById('word-line-intro'))) {
      const svgLineIntro = document.getElementById('svg-line-intro')
      const wordLineIntro = document.getElementById('word-line-intro')

      const rectLineIntro = wordLineIntro.getBoundingClientRect()

      svgLineIntro.style.top = rectLineIntro.bottom - 10 + window.scrollY + 'px'
      svgLineIntro.style.left = rectLineIntro.left + 'px'
      svgLineIntro.style.width = rectLineIntro.width + 'px'
    }

    const svgCircleOutro = document.getElementById('svg-circle-outro')
    const wordCircleOutro = document.getElementById('word-circle-outro')

    const rectCircleOutro = wordCircleOutro.getBoundingClientRect()

    svgCircleOutro.style.width = rectCircleOutro.width + 'px'
    svgCircleOutro.style.top =
      rectCircleOutro.top +
      rectCircleOutro.height / 2 -
      svgCircleOutro.offsetHeight / 2 +
      window.scrollY +
      'px'
    svgCircleOutro.style.left = rectCircleOutro.left + 'px'

    const svgLineOutro = document.getElementById('svg-line-outro')
    const wordLineOutro = document.getElementById('word-line-outro')

    const rectLineOutro = wordLineOutro.getBoundingClientRect()

    svgLineOutro.style.top = rectLineOutro.bottom - 10 + window.scrollY + 'px'
    svgLineOutro.style.left = rectLineOutro.left + 'px'
    svgLineOutro.style.width = rectLineOutro.width + 'px'
  }
}
